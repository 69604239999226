<template>
  <div v-if="isVisible">
    <vs-row v-if="isList()">
      <vs-col
        v-if="!isHideLabel()"
        vs-w="4"
        vs-type="flex"
        vs-justify="left"
        vs-align="center"
        class="mt-1 mb-1 property-name"
      >
        {{ getTranslatedLabel() }}:
        <sup v-if="haveTooltip()">
          <vs-tooltip :text="getTranslatedTooltip()">
            <info-icon class="infotooltip" size="18" />
          </vs-tooltip>
        </sup>
      </vs-col>
      <vs-col :vs-w="getListWidth()" vs-type="flex" vs-justify="left" vs-align="center">
        <ValidationProvider :rules="getFieldRules()" tag="div" :class="{'max-width': isMaxWidth()}">
          <template v-slot="{ errors }">
            <vs-row>
              <vs-col vs-w="12">
                <div
                  v-for="(sectionValue, sectionIndex) in configValue"
                  :key="sectionIndex"
                  class="section-list-content"
                >
                  <div class="row">
                    <div class="col-12 section-list-content-list">
                      <div class="row">
                        <div
                          v-for="(value, name, index) in getSectionKeys(sectionValue, sectionIndex)"
                          :key="index"
                          :class="getColSizeClass()"
                        >
                          <div v-if="isMain()">
                            <div class="row">
                              <AppV3SettingsItem
                                :config="value"
                                :configkey="name"
                                :field-in-new-line="true"
                                :class="{'col-10':isMain() && index===0, 'col-12':!isMain() || index!==0}"
                                @changeConfig="onChange"
                              />
                              <div class="col-2 section-list-content-remove" v-if="isMain() && index===0">
                                <b-button
                                  variant="outline-primary"
                                  class="w-100"
                                  @click="removeListElement(sectionIndex)">
                                  <trash2-icon size="16" ></trash2-icon>
                                  {{ $t('appV3Settings.delete') }}
                                </b-button>
                              </div>
                            </div>
                          </div>
                          <div v-if="!isMain()">
                            <AppV3SettingsItem
                              :config="value"
                              :configkey="name"
                              :field-in-new-line="true"
                              @changeConfig="onChange"
                            />
                          </div>
                        </div>
                        <div
                          v-if="needSpace()"
                          :class="getSpaceClassSize()"
                          >&nbsp;</div>
                        <div class="col-2 section-list-content-remove" v-if="!isMain()">
                          <b-button variant="outline-primary" class="w-100" @click="removeListElement(sectionIndex)">
                            <trash2-icon size="16" ></trash2-icon> {{ $t('appV3Settings.delete') }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 section-list-bottom">
                  <b-button variant="outline-primary" @click="addListElement">{{ getTranslatedLabelAdd() }}</b-button>
                </div>
              </vs-col>
              <vs-col vs-w="12">
                <span class="error">{{ errors[0] }}</span>
              </vs-col>
            </vs-row>
          </template>
        </ValidationProvider>
      </vs-col>
    </vs-row>
    <vs-row v-if="!isList()">
      <vs-col :vs-w="getLabelSize()" vs-type="flex" vs-justify="left" vs-align="center" class="mt-1 mb-1 property-name">
        {{ getTranslatedLabel() }}:
        <sup v-if="haveTooltip()">
          <vs-tooltip :text="getTranslatedTooltip()">
            <info-icon class="infotooltip" size="18"/>
          </vs-tooltip>
        </sup>
      </vs-col>
      <vs-col :vs-w="getFieldSize()" vs-type="flex" vs-justify="left" vs-align="center">
        <ValidationProvider :rules="getFieldRules()" tag="div" :class="{'max-width': isMaxWidth()}">
          <template v-slot="{ errors }">
            <vs-row>
              <vs-col vs-w="12">
                <vs-input
                  v-if="isEditField()"
                  v-model="configValue"
                  class="max-width"
                />
                <b-textarea
                  v-if="isTextareaField()"
                  v-model="configValue"
                  class="max-width"
                />
                <vs-select
                  v-if="isEnumField()"
                  v-model="configValue"
                  class="max-width"
                >
                  <vs-select-item
                    v-for="(option) in getFieldOptions()"
                    :key="option"
                    :value="option"
                    :text="option"
                  />
                </vs-select>
                <vs-switch
                  v-if="isBooleanField()"
                  v-model="configValue"
                  class="inline-flex"
                >
                  <template v-slot:on>
                    {{ $t('appV3Settings.fieldValue.true') }}
                  </template>
                  <template v-slot:off>
                    {{ $t('appV3Settings.fieldValue.false') }}
                  </template>
                </vs-switch>
                <vs-input-number
                  v-if="isNumberField()"
                  v-model="configValue"
                  :min="1"
                  :step="1"
                />
              </vs-col>
              <vs-col vs-w="12">
                <span class="error">{{ errors[0] }}</span>
              </vs-col>
            </vs-row>
          </template>
        </ValidationProvider>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import Vue from 'vue'
import moduleAppV3Settings from '@/store/appV3/moduleAppV3Settings'
import { InfoIcon, Trash2Icon } from 'vue-feather-icons'

export default Vue.extend({
  name: 'AppV3SettingsItem',
  components: { InfoIcon, Trash2Icon },
  props: ['config', 'configkey', 'fieldInNewLine'],
  emits: ['changeConfig'],
  computed: {
    configValue: {
      get() {
        return this.config
      },
      set(newValue) {
        let setValue = newValue
        if (this.getType(this.configValue) === 'number') {
          setValue = parseFloat(setValue)
        }
        this.update(setValue)
      },
    },
    isVisible: {
      get() {
        return moduleAppV3Settings.getters.isVisible(this.configkey)
      },
      set() {
      },
    },
  },
  mounted() {
    this.$root.$on('settingsChangeValues', () => {
      this.isVisible = moduleAppV3Settings.getters.isVisible(this.configkey)
    })
  },
  methods: {
    getLabelSize() {
      return this.fieldInNewLine ? 12 : 4
    },
    getFieldSize() {
      return this.fieldInNewLine ? 12 : 8
    },
    getSectionKeys(sectionData, subsection) {
      let sectionKeys = {}
      Object.keys(sectionData).forEach(index => {
        const subsectionKey = [subsection, index].join('.')
        let testSectionFieldName = subsectionKey
        if (this.isList()) {
          testSectionFieldName = `${this.configkey}.${subsectionKey}`
        }
        if (typeof sectionData[index] === 'object') {
          if (moduleAppV3Settings.getters.isList(testSectionFieldName)) {
            sectionKeys[testSectionFieldName] = sectionData[index]
            return
          }
          sectionKeys = Object.assign(
            sectionKeys,
            this.getSectionKeys(
              sectionData[index],
              subsectionKey,
            ),
          )
          return
        }
        sectionKeys[testSectionFieldName] = sectionData[index]
      })
      return sectionKeys
    },
    isMaxWidth() {
      return this.getType() !== 'number'
    },
    getFieldOptions() {
      return moduleAppV3Settings.getters.getFieldOptions(this.configkey)
    },
    getFieldRules() {
      if (this.isList()) {
        return {}
      }
      return moduleAppV3Settings.getters.getFieldRules(this.configkey)
    },
    isEditField() {
      return this.getType(this.configValue) === 'string' && !this.getFieldOptions() && !this.isTextareaField()
    },
    isTextareaField() {
      return moduleAppV3Settings.getters.isTextareaField(this.configkey)
    },
    isEnumField() {
      return this.getType(this.configValue) === 'string' && this.getFieldOptions() && !this.isTextareaField()
    },
    isBooleanField() {
      return this.getType(this.configValue) === 'boolean'
    },
    isNumberField() {
      return this.getType(this.configValue) === 'number'
    },
    isList() {
      return moduleAppV3Settings.getters.isList(this.configkey)
    },
    isHideLabel() {
      return moduleAppV3Settings.getters.isList(this.configkey).hideLabel
    },
    getColSize() {
      const fieldInRow = (moduleAppV3Settings.getters.isList(this.configkey).colCount ?? 0)
      let size = (12 / fieldInRow)
      if (this.isMain()) {
        size = (10 / fieldInRow) + 2
      } else {
        size = Math.floor(size - (2 / fieldInRow))
      }
      return size
    },
    getSpaceSize() {
      return 12 - (this.getColSize() * (moduleAppV3Settings.getters.isList(this.configkey).colCount ?? 0) + 2)
    },
    needSpace() {
      return this.getSpaceSize() > 0
    },
    getSpaceClassSize() {
      const size = this.getSpaceSize()
      return `col-${size ?? 12}`
    },
    getColSizeClass() {
      const size = this.getColSize()
      return `col-${size ?? 12}`
    },
    getListWidth() {
      const listDefinition = moduleAppV3Settings.getters.isList(this.configkey)
      if (listDefinition.isMain) {
        return 8
      }
      return 12
    },
    isMain() {
      return moduleAppV3Settings.getters.isList(this.configkey).isMain ?? false
    },
    getType() {
      return typeof this.config
    },
    update(value) {
      this.$emit('changeConfig', this.configkey, value)
    },
    getTranslatedLabel() {
      let patternKeyLabel = this.configkey.replaceAll(/\.\d+\./g, '.*.')
      if (this.isList()) {
        patternKeyLabel = `${patternKeyLabel}List`
      }
      return this.$t(`appV3Settings.fields.${patternKeyLabel}`)
    },
    getTranslatedLabelAdd() {
      let patternKeyLabel = this.configkey.replaceAll(/\.\d+\./g, '.*.')
      if (this.isList()) {
        patternKeyLabel = `${patternKeyLabel}List`
      }
      return this.$t(`appV3Settings.fields.${patternKeyLabel}Add`)
    },
    getTranslatedTooltip() {
      let patternKeyLabel = this.configkey.replaceAll(/\.\d+\./g, '.*.')
      if (this.isList()) {
        patternKeyLabel = `${patternKeyLabel}List`
      }
      return this.$t(`appV3Settings.fieldsTooltip.${patternKeyLabel}`)
    },
    haveTooltip() {
      return this.getTranslatedTooltip() !== ''
    },
    addListElement() {
      moduleAppV3Settings.dispatch('addListElement', {
        path: this.configkey,
        config: null,
      })
    },
    removeListElement(sectionIndex) {
      if (window.confirm(this.$t('appV3Settings.deleteAsk'))) {
        moduleAppV3Settings.dispatch('removeListElement', {
          path: this.configkey,
          config: null,
          itemIndex: sectionIndex,
        })
      }
    },
    onChange(key, config) {
      moduleAppV3Settings.dispatch(
        'setSettingValue',
        {
          path: key,
          value: config,
          config: null,
        },
      )
      this.$root.$emit('settingsChangeValues', '')
    },
  },
})
</script>

<style scoped>
.max-width {
  width: 100%;
}

.error {
  color: red;
}
</style>

<style scoped>
.section-list-bottom {
  text-align: left;
  margin-bottom: 25px;
  margin-top: 8px;
  padding-left: 0;
}

.section-list-content-list {
  float: left;
  width: calc(100%);
}

.infotooltip {
  background-color: #39ba9b;
  color: white;
  border-radius: 50%;
  margin-left: 8px;
}

.section-list-content-remove {
  display: grid;
  align-self: end;
}
</style>
