import Notifications from '@/store/notifications/notifications'
import Vue from 'vue'
import Vuex from 'vuex'
import { SearchApiRequest } from '@/store/search/searchApiRequestHelper'
import i18n from '@/libs/i18n'
import {
  addListElementPayload,
  AppV3Settings, createDeepPayload, getAvailableCurrencies, getPatterPath, getValueFromPath, LISTFIELDS,
  ModuleAppV3State, removeListElementPayload,
  setSettingPayload, tryFindPathGroup, VALIDATORS,
} from '@/store/appV3/moduleAppV3SettingsDef'

Vue.use(Vuex)

export default new Vuex.Store({
  state: (): ModuleAppV3State => (
    {
      isLoaded: false,
      settings: null,
    }
  ),
  mutations: {
    setSettings(state: ModuleAppV3State, settings: AppV3Settings) {
      state.settings = settings
      state.isLoaded = true
    },
  },
  actions: {
    loadSettings(context: any, searchId: string) {
      SearchApiRequest.getAppV3Settings(searchId).then(results => {
        context.commit('setSettings', results.data.settings)
      }).catch(error => {
        Notifications.commit('addError', error, { root: true })
      })
    },
    saveSettings(context: any, searchId: string) {
      SearchApiRequest.setAppV3Settings(searchId, context.state.settings).then(() => {
        Notifications.commit('addPopupInfo', {
          name: i18n.t('appV3Settings.saveSuccess'),
          message: i18n.t('appV3Settings.saveSuccess'),
        }, { root: true })
      }).catch(error => {
        Notifications.commit('addError', error, { root: true })
      })
    },
    setSettingValue(context: any, configPayload: setSettingPayload) {
      const pathElements = configPayload.path.split('.')
      const firstPathElement = pathElements.shift()
      const configObject = configPayload.config ?? context.state.settings

      if (!Object.keys(configObject).includes(firstPathElement as string)) {
        return
      }
      if (!pathElements.length) {
        configObject[firstPathElement as string] = configPayload.value
        return
      }
      context.dispatch('setSettingValue', {
        path: pathElements.join('.'),
        value: configPayload.value,
        config: configObject[firstPathElement as string],
      })
    },
    addListElement(context: any, payload: addListElementPayload) {
      const pathElements = payload.path.split('.')
      const firstPathElement = pathElements.shift()
      const configObject = payload.config ?? context.state.settings
      if (!Object.keys(configObject).includes(firstPathElement as string)) {
        return
      }
      if (!pathElements.length) {
        const patternPath = getPatterPath(payload.realPath ?? '')
        const listDefinition = LISTFIELDS[patternPath as keyof typeof LISTFIELDS] ?? false
        if (!listDefinition) {
          return
        }
        if (!listDefinition.proto) {
          return
        }
        configObject[firstPathElement as string].push({ ...listDefinition.proto })
        context.dispatch('createDeepListProto', {
          path: `${payload.realPath}.${configObject[firstPathElement as string].length - 1}`,
        })
        return
      }
      context.dispatch('addListElement', {
        path: pathElements.join('.'),
        config: configObject[firstPathElement as string],
        realPath: payload.realPath ?? payload.path,
      })
    },

    removeListElement(context: any, payload: removeListElementPayload) {
      const pathElements = payload.path.split('.')
      const firstPathElement = pathElements.shift()
      const configObject = payload.config ?? context.state.settings
      if (!Object.keys(configObject).includes(firstPathElement as string)) {
        return
      }
      if (!pathElements.length) {
        configObject[firstPathElement as string].splice(payload.itemIndex, 1)
      }
      context.dispatch('removeListElement', {
        path: pathElements.join('.'),
        config: configObject[firstPathElement as string],
        itemIndex: payload.itemIndex,
      })
    },

    createDeepListProto(context: any, payload: createDeepPayload) {
      const pathElements = payload.path.split('.')
      const firstPathElement = pathElements.shift()
      const configObject = payload.config ?? context.state.settings
      if (!pathElements.length) {
        Object.keys(configObject[firstPathElement as string]).forEach(propertyName => {
          const realPath = `${payload.realPath}.${propertyName}`
          const patternPath = getPatterPath(realPath ?? '')
          if (context.getters.isList(patternPath)) {
            configObject[firstPathElement as string][propertyName] = []
          }
        })
        return
      }
      context.dispatch('createDeepListProto', {
        path: pathElements.join('.'),
        config: configObject[firstPathElement as string],
        realPath: payload.realPath ?? payload.path,
      })
    },
  },
  getters: {
    isLoaded(state: ModuleAppV3State) {
      return state.isLoaded
    },
    getSettings(state: ModuleAppV3State) {
      return state.settings
    },
    isList: () => (configKey: string) => {
      const patternConfigKey = getPatterPath(configKey)
      return LISTFIELDS[patternConfigKey as keyof typeof LISTFIELDS] ?? false
    },
    isTextareaField: () => (configKey: string) => {
      const validator = VALIDATORS[getPatterPath(configKey)]
      if (validator && 'textarea' in validator) {
        return validator.textarea ?? false
      }
      return false
    },
    getFieldOptions: () => (configKey: string) => {
      const patternConfigKey = getPatterPath(configKey)
      if (patternConfigKey === 'appConfiguration.currency') {
        return getAvailableCurrencies()
      }
      return false
    },
    getFieldRules: () => (configKey: string) => {
      let rules = { required: true }
      const patternConfigKey = getPatterPath(configKey)
      if (patternConfigKey in VALIDATORS) {
        const { textarea, ...fieldRules } = VALIDATORS[patternConfigKey as keyof typeof VALIDATORS]
        rules = Object.assign(rules, fieldRules)
      }
      return rules
    },
    isVisible: (state: ModuleAppV3State) => (configKey: string) => {
      const FieldGrupDefinition = tryFindPathGroup(configKey)
      if (FieldGrupDefinition === null) {
        return true
      }
      if (!('dependent' in FieldGrupDefinition)) {
        return true
      }
      return (getValueFromPath(state.settings, FieldGrupDefinition['dependent' as keyof typeof FieldGrupDefinition], false))
    },
  },
})
