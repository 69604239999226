// BUG eslint https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope
// eslint-disable-next-line no-shadow
export enum AppV3Currencies {
  'CZK' = 1,
  'EUR',
  'PLN',
  'HUF',
  'HRK',
  'UAH',
  'GBP',
  'DKK',
  'CHF',
  'NOK',
  'SEK'
}

export function getAvailableCurrencies() {
  return Object.values(AppV3Currencies)
    .filter(value => typeof value === 'string') as string[]
}

export function getPatterPath(configKey: string) {
  return configKey.replaceAll(/\.\d+\./g, '.*.')
}

export interface Validator {
  regex?: RegExp;
  oneOf?: string[];
  required?: boolean;
  textarea?: boolean;
  minValue?: number;
}

export interface Validators {
  [key: string]: Validator;
}

export interface FieldGroup {
  dependent?: string;
  group?: string
}

interface Groups {
  [key: string]: FieldGroup
}

export const APPV3_SETTINGS_COLLAPSE = [
  {
    path: 'appConfiguration.*',
    header: false,
  },
  {
    path: 'onfocus.*',
    header: false,
  },
  {
    path: 'onfocus.popularProducts.*',
    header: true,
  },
  {
    path: 'onfocus.popularQueries.*',
    header: true,
  },
  {
    path: 'onfocus.predefinedQueries.*',
    header: true,
  },
  {
    path: 'onfocus.productImageSize.*',
    header: true,
  },
  {
    path: 'onfocus.recentQueries.*',
    header: true,
  },
  {
    path: 'onfocus.topProduct.*',
    header: true,
  },
  {
    path: 'autocomplete.*',
    header: false,
  },
  {
    path: 'autocomplete.bestseller',
    header: true,
  },
  {
    path: 'autocomplete.productImageSize',
    header: true,
  },
  {
    path: 'autocomplete.results',
    header: true,
  },
  {
    path: 'autocomplete.suggestions',
    header: true,
  },

  {
    path: 'serp.*',
    header: false,
  },
  {
    path: 'serp.query',
    header: true,
  },
  {
    path: 'serp.sorting',
    header: true,
  },
  {
    path: 'serp.tagList',
    header: true,
  },
  {
    path: 'serp.productImageSize',
    header: true,
  },
  {
    path: 'serp.filters',
    header: true,
  },
  {
    path: 'serp.gridSettings',
    header: true,
  },
  {
    path: 'serp.markedFilters',
    header: true,
  },
  {
    path: 'serp.pagination',
    header: true,
  },
  {
    path: 'serp.customComponents',
    header: true,
  },
]

export const APPV3_SETTINGS_GROUPS: Groups = {
  'onfocus.popularQueries.count': {
    dependent: 'onfocus.popularQueries.enabled',
    group: 'popularQueries',
  },
  'onfocus.popularProducts.count': {
    dependent: 'onfocus.popularProducts.enabled',
    group: 'popularProducts',
  },
  'onfocus.popularProducts.sortName': {
    dependent: 'onfocus.popularProducts.enabled',
    group: 'popularProducts',
  },
  'onfocus.predefinedQueries.sections': {
    dependent: 'onfocus.predefinedQueries.enabled',
    group: 'predefinedQueries',
  },
  'onfocus.recentQueries.count': {
    dependent: 'onfocus.recentQueries.enabled',
    group: 'recentQueries',
  },
  'onfocus.topProduct.productImageSize.height': {
    dependent: 'onfocus.topProduct.enabled',
    group: 'topProduct',
  },
  'onfocus.topProduct.productImageSize.width': {
    dependent: 'onfocus.topProduct.enabled',
    group: 'topProduct',
  },
  'autocomplete.suggestions.count': {
    dependent: 'autocomplete.suggestions.enabled',
    group: 'suggestions',
  },
  'serp.tagList.limitPerFilter': {
    dependent: 'serp.tagList.showTagList',
    group: 'suggestions',
  },
}

export function tryFindPathGroup(path: string): object | null {
  if (path in APPV3_SETTINGS_GROUPS) {
    return APPV3_SETTINGS_GROUPS[path as keyof typeof APPV3_SETTINGS_GROUPS]
  }
  return null
}

export const VALIDATORS: Validators = {
  'appConfiguration.redirectionUrl': {
    regex: /^[\w\-.~\\/?&=#%+[\]!$()*;]+$/,
  },
  'appConfiguration.currency': {
    oneOf: getAvailableCurrencies(),
  },
  'serp.customComponents.productCard': { required: false, textarea: true },
  'onfocus.predefinedQueries.sections.*.queries.*.link': {
    required: false,
    regex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/,
  },
  'serp.customComponents.autocompleteSingleResult': { required: false, textarea: true },
  'serp.customComponents.singlePopularProduct': { required: false, textarea: true },
  'serp.customComponents.topProduct': { required: false, textarea: true },
  'onfocus.productImageSize.width': { minValue: 1 },
  'onfocus.productImageSize.height': { minValue: 1 },
  'onfocus.popularQueries.count': { minValue: 1 },
  'onfocus.popularProducts.count': { minValue: 1 },
  'onfocus.topProduct.productImageSize.width': { minValue: 1 },
  'onfocus.topProduct.productImageSize.height': { minValue: 1 },
  'autocomplete.productImageSize.width': { minValue: 1 },
  'autocomplete.productImageSize.height': { minValue: 1 },
  'autocomplete.suggestions.count': { minValue: 1 },
  'autocomplete.results.count': { minValue: 1 },
  'serp.productImageSize.width': { minValue: 1 },
  'serp.productImageSize.height': { minValue: 1 },
  'serp.gridSettings.column.smallDevice': { minValue: 1 },
  'serp.gridSettings.column.mediumDevice': { minValue: 1 },
  'serp.gridSettings.column.largeDevice': { minValue: 1 },
  'serp.gridSettings.column.extraLargeDevice': { minValue: 1 },
}

export interface AppV3AppConfiguration {
  applicationEnabled: boolean
  redirectionUrl: string
  removeDecimals: boolean
  currency: AppV3Currencies
  alternativeQueryInject: boolean
}

interface PredefinedQueriesSectionQuery {
  query: string,
  link: string,
}

interface PredefinedQueriesSection {
  header: string
  queries: {
    [index: number]: PredefinedQueriesSectionQuery
  }
}

interface PredefinedQueriesSections {
  [index: number]: PredefinedQueriesSection
}

interface AppV3AppOnfocus {
  showOnfocus: boolean,
  productImageSize:
    {
      fixedWidth: boolean
      width: number
      fixedHeight: boolean
      height: number
    },
  recentQueries: {
    enabled: boolean
    count: number
  },
  popularQueries: {
    enabled: boolean
    count: number
  },
  predefinedQueries: {
    enabled: boolean
    sections: PredefinedQueriesSections,
  },
  popularProducts: {
    enabled: boolean
    count: number
    sortName: string
  }
  topProduct: {
    enabled: boolean
    productImageSize: {
      fixedWidth: boolean
      width: number
      fixedHeight: boolean
      height: number
    }
  },
}

interface AppV3Autocomplete {
  productImageSize: {
    fixedWidth: boolean,
    width: number,
    fixedHeight: boolean,
    height: number
  },
  suggestions: {
    enabled: boolean,
    count: number
  },
  results: {
    count: number
  }
}

interface AppV3Serp {
  query: {
    allowEmptyQuery: boolean
  },
  filters: {
    showAsideFilters: boolean
    showTopFilters: boolean
  },
  sorting: {
    showSorting: boolean
  },
  pagination: {
    useTop: boolean
    useBottom: boolean
  },
  backToTopButton: {
    enabled: boolean
  },
  markedFilters: {
    enabled: boolean
  },
  productImageSize: {
    useQuarticImages: boolean
    fixedWidth: boolean
    width: number
    fixedHeight: boolean
    height: number
  },
  gridSettings: {
    column: {
      smallDevice: number
      mediumDevice: number
      largeDevice: number
      extraLargeDevice: number
    }
  },
  customComponents: {
    productCard: string
    autocompleteSingleResult: string
    singlePopularProduct: string
    topProduct: string
  }
}

interface ListDefinition {
  min?: Number;
  max?: Number;
  isMain?: boolean;
  proto?: Object;
  hideLabel?: boolean,
  colCount?: number,
}

interface ListFields {
  [key: string]: ListDefinition;
}

export const LISTFIELDS: ListFields = {
  'onfocus.predefinedQueries.sections': {
    isMain: true,
    hideLabel: false,
    colCount: 1,
    proto: {
      header: '',
      queries: '',
    },
  },
  'onfocus.predefinedQueries.sections.*.queries': {
    isMain: false,
    hideLabel: true,
    colCount: 2,
    proto: {
      query: '',
      link: '',
    },
  },
}

export interface AppV3Settings {
  appConfiguration: AppV3AppConfiguration
  onfocus: AppV3AppOnfocus
  autocomplete: AppV3Autocomplete
  serp: AppV3Serp
}

export interface setSettingPayload {
  path: string
  value: any,
  config: object | null
}

export interface addListElementPayload {
  path: string
  config: object | null
  realPath?: string
}

export interface removeListElementPayload {
  path: string
  config: object | null
  itemIndex: Number
}

export interface createDeepPayload {
  path: string
  config: object | null
  realPath?: string
}

export interface ModuleAppV3State {
  isLoaded: boolean
  settings: AppV3Settings | null
}

export function getValueFromPath(state: Object | null, path: string, defaultValue: any): any | null {
  if (state == null) {
    return defaultValue
  }
  const pathElements = path.split('.')
  const firstPathElement = pathElements.shift()
  if (!Object.keys(state).includes(firstPathElement as string)) {
    return defaultValue
  }
  const element = state[firstPathElement as keyof typeof state]
  if (!pathElements.length) {
    return element
  }
  return getValueFromPath(element, pathElements.join('.'), defaultValue)
}

export function tryFindCollapsedGroupForPath(path: string) {
  let result = null
  APPV3_SETTINGS_COLLAPSE.forEach((collapsedGroup, index) => {
    const regExpExpression = collapsedGroup.path.replaceAll('.', '\\.').replaceAll('*', '[\\w]+')
    if ((new RegExp(regExpExpression)).exec(path) !== null) {
      result = index
    }
  })
  return result
}
