<template>
  <div ref="container">
    <ValidationObserver ref="observer">
      <b-card :title="$t('appV3Settings.header')">
        <vs-tabs v-if="isLoaded">
          <vs-tab :label="$t('appV3Settings.tabGeneral')">
            <vs-col vs-w="12" class="mb-2">
              {{$t('appV3Settings.desc.appConfiguration')}}
            </vs-col>
            <div
              v-for="(value, index) in getCollapsedKeys(getSettings.appConfiguration, 'appConfiguration')"
              :key="index"
            >
              <AppV3SettingsCollapse
                v-if="value != null"
                :collapse-group-keys="value"
                :collapse-group-id="index"
              />
            </div>
          </vs-tab>
          <vs-tab :label="$t('appV3Settings.tabOnfocus')">
            <vs-col vs-w="12" class="mb-2 desc" >
              <div class="desc-content">
                {{$t('appV3Settings.desc.onfocus')}}
              </div>
              <img src="@/assets/images/settings/onfocus.png" class="desc-image">
            </vs-col>
            <div
              v-for="(value, index) in getCollapsedKeys(getSettings.onfocus, 'onfocus')"
              :key="index"
            >
              <AppV3SettingsCollapse
                v-if="value != null"
                :collapse-group-keys="value"
                :collapse-group-id="index"
              />
            </div>
          </vs-tab>
          <vs-tab :label="$t('appV3Settings.tabAutocomplete')">
            <vs-col vs-w="12" class="mb-2 desc" >
              <div class="desc-content">
                {{$t('appV3Settings.desc.autocomplete')}}
              </div>
              <img src="@/assets/images/settings/autocomplete.png" class="desc-image">
            </vs-col>
            <div
              v-for="(value, index) in getCollapsedKeys(getSettings.autocomplete, 'autocomplete')"
              :key="index"
            >
              <AppV3SettingsCollapse
                v-if="value != null"
                :collapse-group-keys="value"
                :collapse-group-id="index"
              />
            </div>
          </vs-tab>
          <vs-tab :label="$t('appV3Settings.tabSerp')">
            <vs-col vs-w="12" class="mb-2 desc" >
              <div class="desc-content">
                {{$t('appV3Settings.desc.serp')}}
              </div>
              <img src="@/assets/images/settings/serp.png" class="desc-image">
            </vs-col>
            <div
              v-for="(value, index) in getCollapsedKeys(getSettings.serp, 'serp')"
              :key="index"
            >
              <AppV3SettingsCollapse
                v-if="value != null"
                :collapse-group-keys="value"
                :collapse-group-id="index"
              />
            </div>
          </vs-tab>
        </vs-tabs>
        <vs-row v-if="isLoaded" class="mt-2">
          <vs-col vs-lg="9" vs-sm="3" vs-xs="0" />
          <vs-col vs-lg="3" vs-sm="9" vs-xs="12">
            <vs-button class="w-100" @click="save()">
              {{ $t('appV3Settings.save') }}
            </vs-button>
          </vs-col>
        </vs-row>
      </b-card>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from 'vue'
import moduleAppV3Settings from '@/store/appV3/moduleAppV3Settings'
import SearchInstanceStore from '@/store/search/searchInstance'
import { getValueFromPath, tryFindCollapsedGroupForPath } from '@/store/appV3/moduleAppV3SettingsDef'
import AppV3SettingsCollapse from '@/views/components/appV3/AppV3SettingsCollapse.vue'

export default Vue.extend({
  name: 'AppV3Settings',
  components: { AppV3SettingsCollapse },
  computed: {
    isLoaded() {
      return moduleAppV3Settings.getters.isLoaded
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
    getSettings() {
      return moduleAppV3Settings.getters.getSettings
    },
  },
  watch: {
    getSearchId() {
      this.getData()
    },
  },
  mounted() {
    if (this.getSearchId !== '') {
      this.getData()
    }
  },
  methods: {
    getCollapsedKeys(sectionData, subsection) {
      const sectionKeys = this.getSectionKeys(sectionData, subsection)
      const collapsedGroups = []
      Object.keys(sectionKeys).forEach(index => {
        const collapsedGroupId = tryFindCollapsedGroupForPath(index)
        if (!collapsedGroups[collapsedGroupId]) {
          collapsedGroups[collapsedGroupId] = []
        }
        collapsedGroups[collapsedGroupId].push({ path: index, value: getValueFromPath(moduleAppV3Settings.getters.getSettings, index, false) })
      })
      return collapsedGroups
    },
    getSectionKeys(sectionData, subsection) {
      let sectionKeys = {}
      Object.keys(sectionData).forEach(index => {
        const subsectionKey = [subsection, index].join('.')
        if (typeof sectionData[index] === 'object') {
          if (moduleAppV3Settings.getters.isList(subsectionKey)) {
            sectionKeys[subsectionKey] = sectionData[index]
            return
          }
          sectionKeys = Object.assign(
            sectionKeys,
            this.getSectionKeys(
              sectionData[index],
              subsectionKey,
            ),
          )
          return
        }
        sectionKeys[subsectionKey] = sectionData[index]
      })
      return sectionKeys
    },
    onChange(key, config) {
      moduleAppV3Settings.dispatch(
        'setSettingValue',
        {
          path: key,
          value: config,
          config: null,
        },
      )
      this.$root.$emit('settingsChangeValues', '')
    },
    getData() {
      moduleAppV3Settings.dispatch('loadSettings', this.getSearchId)
    },
    save() {
      return this.$refs.observer.validate()
        .then(validationResult => {
          if (validationResult) {
            moduleAppV3Settings.dispatch('saveSettings', this.getSearchId)
            return true
          }
          return false
        })
    },
  },
})
</script>

<style scoped>
  .desc{
    margin-left: 0%;
    width: 100%;
    display: grid;
    grid-template-columns: auto 300px;
  }
  .desc-image{
    width: 100%;
    max-height: 200px;
  }
  .desc-content{
    text-align: center;
    align-self: center;
    padding:0px 2rem;
  }
</style>
