<template>
  <div>
    <div v-if="getCollapseGroupSettings(collapseGroupId).header">
      <vs-collapse>
        <vs-collapse-item>
          <div slot="header">
            {{ getTranslatedCollapseGroupLabel(collapseGroupId) }}
          </div>
          <div v-for="(collapsedGroupField, index) in collapseGroupKeys" :key="index">
            <AppV3SettingsItem
              :config="collapsedGroupField.value"
              :configkey="collapsedGroupField.path"
              @changeConfig="onChange"
            />
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </div>
    <div v-else v-for="(collapsedGroupField, index) in collapseGroupKeys" :key="index">
      <AppV3SettingsItem
        :config="collapsedGroupField.value"
        :configkey="collapsedGroupField.path"
        @changeConfig="onChange"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { APPV3_SETTINGS_COLLAPSE } from '@/store/appV3/moduleAppV3SettingsDef'
import moduleAppV3Settings from '@/store/appV3/moduleAppV3Settings'
import AppV3SettingsItem from '@/views/components/appV3/AppV3SettingsItem.vue'

export default Vue.extend({
  name: 'AppV3SettingsCollapse',
  components: { AppV3SettingsItem },
  props: ['collapseGroupId', 'collapseGroupKeys'],
  methods: {
    getCollapseGroupSettings(groupId) {
      return APPV3_SETTINGS_COLLAPSE[groupId]
    },
    getTranslatedCollapseGroupLabel(groupId) {
      const translationKey = APPV3_SETTINGS_COLLAPSE[groupId].path.replaceAll('.*', '')
      return this.$t(`appV3Settings.group.${translationKey}`)
    },
    onChange(key, config) {
      moduleAppV3Settings.dispatch(
        'setSettingValue',
        {
          path: key,
          value: config,
          config: null,
        },
      )
      this.$root.$emit('settingsChangeValues', '')
    },
  },
})
</script>

<style>
.vs-collapse-item.open-item  .vs-collapse-item--content{
    max-height: fit-content !important;
}
.vs-collapse, .vs-collapse-item--header{
  padding-left: 0;
}
</style>
